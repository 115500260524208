import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

interface ScheduleAutomationModalProps {
  show: boolean;
  onClose: () => void;
  onSave: (time: string, interval: string) => void;
}

interface FormErrors {
  time?: string;
  interval?: string;
}

const ScheduleAutomationModal: React.FC<ScheduleAutomationModalProps> = ({
  show,
  onClose,
  onSave,
}) => {
  const [time, setTime] = useState<string>("");
  const [interval, setInterval] = useState<string>("");
  const [errors, setErrors] = useState<FormErrors>({});

  const handleSave = () => {
    if (!time) {
      // alert("Please set both time and interval.");
      setErrors((prevErrors) => ({
        ...prevErrors,
        time: "Please set the time",
      }));
      // return;
    }
    if (!interval) {
      // alert("Please set both time and interval.");
      setErrors((prevErrors) => ({
        ...prevErrors,
        interval: "Please set the interval",
      }));
      // return;
    }
    if (time || interval) {
      onSave(time, interval);
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Set Automation Schedule</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="automationTime">
            <Form.Label>Time</Form.Label>
            <Form.Control
              type="time"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              required
            />
            {errors?.time && (
              <p className="error">{errors.time}</p>
            )}
          </Form.Group>

          <Form.Group controlId="automationInterval" className="mt-3">
            <Form.Label>Interval</Form.Label>
            <Form.Control
              as="select"
              value={interval}
              onChange={(e) => setInterval(e.target.value)}
              required
            >
              <option value="">Select an interval</option>
              <option value="daily">Daily</option>
              {/* <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option> */}
            </Form.Control>
            {errors?.interval && (
              <p className="error">{errors.interval}</p>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        {/* <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button> */}
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ScheduleAutomationModal;
