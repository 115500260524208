import { toast } from "react-toastify";
import ScanResults from "./ScanResults";
import ScheduleAutomationModal from "./ScheduleAutomationModal";
import "../../../assets/css/common.css";
import Diagnostic from "./Cards/Diagnostic";
import Calibration from "./Cards/Calibration";
import { CustomDialog } from "react-st-modal";
import { useNavigate } from "react-router-dom";
import SolventReference from "./Cards/SolventReference";
import React, {useState, useEffect, useCallback} from "react";
// import { getDevices } from "../../../views/shared/devices";
import SelectYourHardware from "./Cards/SelectYourHardware";
import { CButton, CCol, CContainer, CRow, CFormSwitch } from "@coreui/react";
import jwtInterceoptor from "../../../views/shared/jwtInterceptor";
import { setCookieItemWithExpiry, isMoreThanOneDay } from "../../../views/shared/utils";

declare global {
	interface Navigator {
		wakeLock: WakeLock;
	}

	interface WakeLock {
		request(type: "screen"): Promise<WakeLockSentinel>;
	}

	interface WakeLockSentinel {
		release(): Promise<void>;
	}
}

type StateObjectTypes = {
	dated: string;
	old: boolean;
};

const initializeStateWithLocalStorageDataAndCheckExpiryDate = (key: string): object => {
	const defaultState = { old: true, dated: "--/--/--" };
	const storedData = localStorage.getItem(key);
	if (storedData) {
		const parsedData = JSON.parse(storedData);
		return { ...parsedData, old: isMoreThanOneDay(parsedData?.dated) };
	}
	return defaultState;
};

const SystemControlCenter = () => {

	const navigate = useNavigate();
  const [automationTime, setAutomationTime] = useState<string | null>(null);
	const [automationInterval, setAutomationInterval] = useState<number | null>(null);
  const [nextScheduledTime, setNextScheduledTime] = useState<string | null>(null);
  const [lastCompletedWorkflow, setLastCompletedWorkflow] = useState<string | null>(null);
	const [latestDiagnostic, setLatestDiagnostic] = useState<StateObjectTypes>(() => initializeStateWithLocalStorageDataAndCheckExpiryDate("latestDiagnostic") as StateObjectTypes);
	const [latestUnitCalibration, setLatestUnitCalibration] = useState<StateObjectTypes>(() => initializeStateWithLocalStorageDataAndCheckExpiryDate("latestUnitCalibration") as StateObjectTypes);
	const [latestSolventReference, setLatestSolventReference] = useState<StateObjectTypes>(() => initializeStateWithLocalStorageDataAndCheckExpiryDate("latestSolventReference") as StateObjectTypes);
  const [showModal, setShowModal] = useState(false);
	const [isExecutingCommands, setIsExecutingCommands] = useState(false);
	const [wakeLock, setWakeLock] = useState<WakeLockSentinel | null>(null);

	// useEffect(() => {
	// 	const devices = getDevices();
	// }, []);

	// Function to handle hardware scanning
	const handleScanHardware = async () => {
		let toastId = null;
		try {
			const shouldTakeNewSolventReference = await CustomDialog(
				<ScanResults
					question={"Is the pipetrain CLEAN and FULL of solvent?"}
					yesOption={"Yes, take a new Solvent Reference"}
                    centerYesButton={true}
					noOption={null}
				/>,
				{
					title: "Scan",
				}
			);

			if (shouldTakeNewSolventReference) {
				toastId = toast.loading("Taking a New Solvent Reference...");
				const response = await jwtInterceoptor.post(
					`${process.env.REACT_APP_API_URL}/user/execute-command/?machine_name=${selectedConnection?.machine_name}&serial_number=${selectedConnection?.serial_number}&device_type=scanner&command=scan_hardware&scan_type=solvent`);
				let latestSolventReference = JSON.stringify({
					...response.data,
					dated: new Date(),
				});
				localStorage.setItem("latestSolventReference", latestSolventReference);
				setLatestSolventReference({dated: new Date() + '', old: false});

				// Store the scan ID in local storage with an expiry time
				setCookieItemWithExpiry("solvent_scan_id", response.data.scan_id);

				toast.update(toastId, {
					render: "Successfully took a new Solvent Reference",
					type: "success",
					isLoading: false,
					autoClose: 2000
				});
			}
		} catch (error) {
			localStorage.removeItem("latestSolventReference");
			if (toastId) {
				toast.update(toastId, {
					render: "Error taking a new Solvent Reference",
					type: "error",
					isLoading: false,
					autoClose: 2000
				});
			}
		}
	};

	const fetchData = async () => {
		const html = "Is the pipetrain CLEAR of solvent and sample?";
		const result = await CustomDialog(
			<ScanResults
				yesOption={"Yes, run a new Diagnostic Scan."}
				noOption={null}
				centerYesButton={true}
				question={html}
			/>,
			{
				title: "Scan",
			}
		);
		if (!result) {
			return
		}
		let toastId = toast.loading("Running System Diagnostic...");
		try {
			const lampResponse = await jwtInterceoptor.post(
				`${process.env.REACT_APP_API_URL}/user/execute-command/?machine_name=${selectedConnection?.machine_name}&serial_number=${selectedConnection?.serial_number}&device_type=scanner&command=diagnostic`,
				"diagnostic_type=lamp",
				{headers: {"Content-Type": "application/x-www-form-urlencoded"}}
			);
			if (lampResponse.status === 201) {
				const failedTests = Object.keys(lampResponse.data).filter(
					(lampKey) => lampResponse.data[lampKey].test_result === "Fail"
				);

				if (failedTests.length > 0) {
					toast.update(toastId, {
						render: "Please Clean the Flow Cell",
						type: "error",
						isLoading: false,
						autoClose: 2000
					});
				} else {
					let latestDiagnostic = JSON.stringify({dated: new Date()});
					localStorage.setItem("latestDiagnostic", latestDiagnostic);
					setLatestDiagnostic({dated: new Date() + '', old: false});
					toast.update(toastId, {
						render: "Diagnostics Ran Successfully",
						type: "success",
						isLoading: false,
						autoClose: 2000
					});
				}
			}
		} catch (error) {
			toast.update(toastId, {
				render: "Error Running the Diagnostics",
				type: "error",
				isLoading: false,
				autoClose: 2000
			});
		}
	};

	const handleYesClick = () => {
		fetchData();
	};

	const fetchDevices = async () => {
		const user_connection = await jwtInterceoptor.get(
			`${process.env.REACT_APP_API_URL}/user/api/user-connection/`
		);
		return user_connection?.data?.results;
	};

	const [connections, setConnection] = useState([]);
	const [selectedConnection, setSelectedConnection] = useState(() => {
		const storedConnection = localStorage.getItem("selectedHardware");
		return storedConnection ? JSON.parse(storedConnection) : null;
	});

	const [deviceStatus, setDeviceStatus] = useState(false);
	useEffect(() => {
		fetchDevices().then((data) => {
			if (data) {
				const currentUrl = window.location.href;
				if (currentUrl.includes('demo')) {
					const filteredData = data.filter(
						(device: { machine_name: string; }) => device.machine_name.toLowerCase() === 'dummy'
					);
					setConnection(filteredData);
				}else{
					setConnection(data);
				}
			}
		});
		//cleanup
		return () => {
			setConnection([]);
		};
	}, []);


	const checkDeviceStatus = useCallback(async (id: number) => {

		let deviceStatus;
		let toastId;
		toastId = toast.loading("Checking Hardware Status");
		try {
			const user_connection = await jwtInterceoptor.get(
				`${process.env.REACT_APP_API_URL}/user/api/user-connection/${id}/`
			);
			let response = user_connection.data;
			deviceStatus = response.status_active;
			setDeviceStatus(deviceStatus);
			toast.update(toastId, {
				render: `${response.machine_name} Connected`,
				type: toast.TYPE.SUCCESS,
				isLoading: false,
				autoClose: 2000,
			});
		} catch (error) {

			toast.update(toastId, {
				render: "Failed to check hardware status",
				type: toast.TYPE.ERROR,
				isLoading: false,
				autoClose: 2000,
			});
		}
	}, [setDeviceStatus]);

	const handleConnections = useCallback(() => {
		if (!selectedConnection) {
			toast.error("Select your Hardware");
			return;
		}
		checkDeviceStatus(selectedConnection.id);
	}, [selectedConnection, checkDeviceStatus]);

	useEffect(() => {
		if (selectedConnection) {
			handleConnections();
			localStorage.setItem(
				"selectedHardware",
				JSON.stringify({...selectedConnection, dated: new Date()})
			);
		}
	}, [handleConnections, selectedConnection]);

	const handleUnitCalibration = async () => {
		var toastId = toast.loading("Calibrating...");
		try {
			const calibration = await jwtInterceoptor.post(
				`${process.env.REACT_APP_API_URL}/user/execute-command/?machine_name=${selectedConnection?.machine_name}&serial_number=${selectedConnection?.serial_number}&device_type=scanner&command=calibrate`
			);
			let calibrationFormattedDate = JSON.stringify({dated: new Date()});
			if (calibration?.status === 201) {
				toast.update(toastId, {
					render: "Successfully Calibrated",
					type: "success",
					isLoading: false,
					autoClose: 2000
				});
				localStorage.setItem("latestUnitCalibration", calibrationFormattedDate);
				calibrationFormattedDate = JSON.parse(calibrationFormattedDate);
				setLatestUnitCalibration({
					dated: new Date() + '',
					old: isMoreThanOneDay(new Date()),
				});
			}
		} catch (err) {
			toast.update(toastId, {
				render: "Error Calibrating Spectrometer",
				type: "error",
				isLoading: false,
				autoClose: 2000
			});
		}
	};

  const openScheduleAutomationModal = async (e: any) => {
    if (e?.target?.checked) {
      // const shouldEnableAutomation = await CustomDialog(
      //   <ScheduleAutomationModal
      //     title="Schedule Automation"
      //     description="Would you like to enable automated scheduling for your tasks?"
      //     confirmText="Yes, enable automation"
      //     cancelText="No, keep it manual"
      //   />,
      //   { title: "Automation Settings" }
      // );
      setShowModal(true);

    } else {
      toast.info("Automation disabled.");
    }
  };

  const handleSave = (time: string, interval: string) => {
    console.log("Automation scheduled at:", time, "with interval:", interval);

    // Calculate next scheduled time for the next day
    const now = new Date();
    const [hours, minutes] = time.split(":").map(Number);
    const nextTime = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, hours, minutes);
    setNextScheduledTime(nextTime.toLocaleString()); // Format as needed

    setAutomationTime(time);
    setAutomationInterval(interval === "daily" ? 86400000 : 10000); // 86400000 ms for daily, 10000 ms for testing
    setShowModal(false);
  };

	// New function to request wake lock
	const requestWakeLock = async () => {
		try {
			const wakeLock = await navigator.wakeLock.request('screen');
			setWakeLock(wakeLock);
			console.log('Wake Lock is active');
		} catch (err: any) {
			console.error(`${err.name}, ${err.message}`);
		}
	};

	// New function to release wake lock
	const releaseWakeLock = async () => {
		if (wakeLock) {
			await wakeLock.release();
			setWakeLock(null);
			console.log('Wake Lock has been released');
		}
	};

	// New function to trigger the commands
	const triggerCommands = async () => {
		console.log("Triggering commands");
		var toastId = toast.loading("diagnosting...");
		setIsExecutingCommands(true);
		await requestWakeLock();
		try {
			await jwtInterceoptor.post(
				`${process.env.REACT_APP_API_URL}/user/execute-command/?machine_name=${selectedConnection?.machine_name}&serial_number=${selectedConnection?.serial_number}&device_type=scanner&command=diagnostic`
			);

			// Update the toast for successful diagnostic completion
			toast.update(toastId, {
				render: "Diagnostic completed successfully",
				type: "success",
				isLoading: false,
				autoClose: 2000
			});

			toastId = toast.loading("calibrating...");
			await jwtInterceoptor.post(
				`${process.env.REACT_APP_API_URL}/user/execute-command/?machine_name=${selectedConnection?.machine_name}&serial_number=${selectedConnection?.serial_number}&device_type=scanner&command=calibrate`
			);

			// Update the toast for successful calibration completion
			toast.update(toastId, {
				render: "Calibration completed successfully",
				type: "success",
				isLoading: false,
				autoClose: 2000
			});

			// Update last completed workflow time
			const now = new Date();
			setLastCompletedWorkflow(now.toLocaleString());

			// Schedule the next execution
			if (automationInterval) {
				setTimeout(triggerCommands, automationInterval);
			}
		} catch (error: any) {
			// Check if the error occurred during calibration
			if (error.response && error.response.data && error.response.data.command === 'calibrate') {
				toast.update(toastId, {
					render: "Error calibrating",
					type: "error",
					isLoading: false,
					autoClose: 2000
				});
			} else {
				toast.update(toastId, {
					render: "Error running diagnostic",
					type: "error",
					isLoading: false,
					autoClose: 2000
				});
			}
			setAutomationTime(null);
			setAutomationInterval(null);
			console.error("Error triggering commands:", error);
		} finally {
			// Only release wake lock if nextScheduledTime is null
			if (!nextScheduledTime) {
				await releaseWakeLock(); // Release wake lock when done
			}
			setIsExecutingCommands(false);
		}
	};

	// Effect to handle automation
	useEffect(() => {
		let intervalId: NodeJS.Timeout | null = null;

		const checkScheduledTime = () => {
			const now = new Date();
			const scheduledTime = new Date();
			if (automationTime) {
				const [hours, minutes] = automationTime.split(":").map(Number);
				scheduledTime.setHours(hours, minutes, 0, 0);
			}

			// Check if the current time matches the scheduled time and if commands are not currently executing
			if (now.getHours() === scheduledTime.getHours() && now.getMinutes() === scheduledTime.getMinutes() && !isExecutingCommands) {
				triggerCommands();
			}
		};

		if (automationInterval && automationTime) {
			checkScheduledTime(); // Initial check

			// Set a timeout to check again after the specified interval
			intervalId = setInterval(checkScheduledTime, 6000); // Check every minute
		}

		return () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [automationTime, automationInterval, isExecutingCommands]);

	// New function to handle visibility change
	const handleVisibilityChange = async () => {
		if (document.visibilityState === 'visible') {
			await requestWakeLock(); // Request wake lock again when the tab is active
		} else {
			await releaseWakeLock(); // Release wake lock when the tab is inactive
		}
	};

	// Add event listener for visibility change
	useEffect(() => {
		document.addEventListener('visibilitychange', handleVisibilityChange);
		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, []);

	return (

		<CContainer>
			<h1
				style={{
					fontWeight: 700,
					fontSize: "25px",
					lineHeight: "43.57px",
					color: "#000000",
					marginBottom: "20px",
					textAlign: "center",
					fontFamily: "Expansiva, sans-serif",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				System Control Center
			</h1>

      <div className="mb-3">
				<div style={{color: '#1488f5', fontSize: '14px', fontWeight: '600', fontFamily: 'Inter'}}>
          <CFormSwitch
            label="Manual / Automated"
						onClick={openScheduleAutomationModal}
            className="d-flex justify-content-center align-items-center gap-2"
            style={{
							backgroundColor: '#1488f5',
							// backgroundColor: '#4e64f5',
						}}
          />
				</div>
			</div>

      <ScheduleAutomationModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onSave={handleSave}
      />
      <div
        className="d-flex justify-content-center align-items-center gap-5 p-3"

      >
        <div>
          Next Scheduled Time :
          <span
            style={{
              fontSize: "14px",
              marginLeft: "5px",
              fontWeight: 700,
              lineHeight: "43.57px",
              color: "#000000",
              marginBottom: "20px",
              textAlign: "center",
              fontFamily: "Expansiva, sans-serif",
              display: "inline",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {nextScheduledTime || "Not scheduled"}
          </span>
        </div>
        <div>
          Last Completed Workflow :
          <span
            style={{
              marginLeft: "5px",
              fontWeight: 700,
              lineHeight: "43.57px",
              color: "#000000",
              marginBottom: "20px",
              textAlign: "center",
              fontFamily: "Expansiva, sans-serif",
              display: "inline",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {lastCompletedWorkflow || "Never"}
          </span>
        </div>
      </div>

			<CContainer className="container">
				<CRow>
					<CCol
						style={{
							display: "flex",
							width: "100%",
							justifyContent: "center",
							marginBottom: "2rem",
						}}
					>
						<SelectYourHardware
							selectedConnection={selectedConnection}
							setSelectedConnection={setSelectedConnection}
							connections={connections}
							deviceStatus={deviceStatus}
							handleConnections={handleConnections} />
					</CCol>
					<CCol
						style={{
							display: "flex",
							width: "100%",
							justifyContent: "center",
							marginBottom: "2rem",
						}}
					>
						<Diagnostic latestDiagnostic={latestDiagnostic} handleYesClick={handleYesClick} />
					</CCol>
					<CCol
						style={{
							display: "flex",
							width: "100%",
							justifyContent: "center",
							marginBottom: "2rem",
						}}
					>
						<Calibration latestUnitCalibration={latestUnitCalibration} handleUnitCalibration={handleUnitCalibration} />
					</CCol>
					<CCol
						style={{
							display: "flex",
							width: "100%",
							justifyContent: "center",
							marginBottom: "2rem",
						}}
					>
					<SolventReference latestSolventReference={latestSolventReference} handleScanHardware={handleScanHardware} />
					</CCol>
				</CRow>
			</CContainer>
			<CRow>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<CButton
						variant="ghost"
						type="submit"
						style={{
							backgroundColor:
								!latestUnitCalibration?.old &&
								!latestDiagnostic?.old &&
								!latestSolventReference?.old
									? "#3AC90A"
									: "#D8D8D8",
							color: "#000000",
							fontWeight: "600",
							height: "45px",
							fontSize: "14px",
							fontFamily: "Inter",
							width: "30%",
							marginTop: "20px",
						}}
						onClick={() => navigate("/ExtractoPredictionDashboard")}
					>
						Start Measuring Sample
					</CButton>
				</div>
			</CRow>
		</CContainer>
	);
};

export default SystemControlCenter;
